import axios from 'axios';
import useAuthStore from '../store/auth';
import Auth from './Auth';

const authUrls = [
  '/auth/login/',
  '/auth/token/refresh/',
  '/auth/password/reset/',
];

const resfreshAccessToken = async () => {
  try {
    let res = await ax.post('/auth/token/refresh/', {
      refresh: Auth.refreshToken,
    });
    const access = res.data.access;
    Auth.accessToken = access;

    return access;
  } catch (e) {
    return null;
  }
};

let ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ax.interceptors.request.use((config) => {
  // Don't add the token if you're using any of the auth urls
  if (authUrls.includes(config.url)) {
    return config;
  }

  const accessToken = Auth.accessToken;
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: originalRequest, response } = error;

    if (response?.status === 503) {
      window.location.href = '/503';
    }

    if (
      originalRequest.url !== '/auth/token/refresh/' &&
      !originalRequest._retry &&
      response?.status === 401
    ) {
      originalRequest._retry = true;
      const access = await resfreshAccessToken();
      if (access) {
        ax.defaults.headers.common['Authorization'] = `Bearer ${access}`;
        return ax(originalRequest);
      } else {
        Auth.logout();
        useAuthStore.setState({
          user: null,
          authenticated: false,
          checkingAuth: false,
        });
      }
    }

    return Promise.reject(error);
  }
);

export default ax;
