import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAuthStore from '../../store/auth';

import logo from '../../assets/duett-logo.svg';
import { useLocation } from 'wouter';
import ax from '../../lib/api';
import shallow from 'zustand/shallow';
import LoadingBox from '../../components/layout/LoadingBox';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `10px solid ${theme.palette.secondary.main}`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 240,
  },
  logo: {
    marginBottom: theme.spacing(8),
  },
  formControl: {
    marginBottom: 24,
  },
  submitButton: {
    marginBottom: 36,
    color: 'white',
  },
  input: {
    marginRight: 0,
  },
  helperText: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const Login = ({ notLoggedIn }) => {
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const [login, checkingAuth] = useAuthStore(
    (state) => [state.login, state.checkingAuth],
    shallow
  );

  const toggleForgotPassword = () => setShowForgotPassword(!showForgotPassword);

  async function handleSubmit(e) {
    e.preventDefault();

    if (showForgotPassword) {
      try {
        let res = await ax.post('/auth/password/reset/', {
          email: email,
        });
        if (res.status === 200) {
          setEmailSent(true);
        }
      } catch (e) {
        console.log('err', e.message);
      }
    } else {
      try {
        await login(email, password);
        if (notLoggedIn) {
          window.reload();
          return;
        }
        setLocation('/');
      } catch (e) {
        setError(e.message);
      }
    }
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <img src={logo} alt="Duett Logo" className={classes.logo} />

        <LoadingBox loading={checkingAuth}>
          {emailSent ? (
            <Typography className={classes.successMessage}>
              Password reset email has been sent.
            </Typography>
          ) : (
            <Box display="flex" flexDirection="column">
              <FormControl className={classes.formControl}>
                <Input
                  id="username-input"
                  placeholder="Email"
                  className={classes.input}
                  disableUnderline={true}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>

              {!showForgotPassword && (
                <FormControl
                  className={classes.formControl}
                  style={{ marginBottom: 36 }}
                >
                  <Input
                    id="password-input"
                    type="password"
                    placeholder="Password"
                    className={classes.input}
                    disableUnderline={true}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <FormHelperText className={classes.helperText} error>
                    {error}
                  </FormHelperText>
                </FormControl>
              )}

              <Button
                variant="contained"
                color="secondary"
                disableElevation
                className={classes.submitButton}
                type={'submit'}
              >
                {showForgotPassword ? 'Submit' : 'Login'}
              </Button>

              <Button onClick={toggleForgotPassword} variant={'text'}>
                {showForgotPassword ? 'Back to Login' : 'Forgot Password?'}
              </Button>
            </Box>
          )}
        </LoadingBox>
      </form>
    </div>
  );
};

export default Login;
